import { useEffect, useState } from "react";

import { Amplify } from 'aws-amplify';
import config from './aws-exports'
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import QRScanner from "./QRScanner";


Amplify.configure(config)

function App() {


  return (
    <Authenticator
    className="mt-5"
     style={{
      display: 'flex',
      justifyContent: 'center',
      height: "100vh"
    }}>
      {({ signOut, user }) => <QRScanner signOut={signOut} user={user} />}
    </Authenticator>
    
  );
}

export default App;
